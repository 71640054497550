import React from "react";
import getFormattedPrice from "../../common/utils/getFormattedPrice";
import getCountryInfoByLanguage from "../../common/utils/getCountryInfoByLanguage";
import { getCurrentLanguage } from "../../actions/auth";
let styles = {};

import(/* webpackMode: "eager" */
`./${process.env.REACT_APP_VENDOR}/Navbar.module.scss`).then(importedStyles => {
  styles = importedStyles.default;
});

const NavbarAdditions = ({ user }) => {
  switch (user.username) {
    case "quota":
      return (
        <div>
          <h2
            className={styles["k42-navbar-header"]}
            style={{ marginTop: "0px", marginBottom: "0px" }}>
            {user.fullname}
          </h2>
          <p
            className={styles["k42-navbar-quota"]}
            style={{
              color:
                user.copiesLeft >= user.quota
                  ? "red"
                  : user.copiesLeft >= user.quota / 3 && "#ffcc33"
            }}>
            Color Quota {user.copiesLeft ? Math.round((user.copiesLeft / user.quota) * 100) : 100}%{" "}
            {user.copiesLeft} of {user.quota}
          </p>
        </div>
      );
    case "credit":
      return (
        <div>
          <h2
            className={styles["k42-navbar-header"]}
            style={{ marginTop: "0px", marginBottom: "0px" }}>
            {user.fullname}
          </h2>
          <p className={styles["k42-navbar-credit"]} style={{ color: user.credit <= 0 && "red" }}>
            Credit{" "}
            {getFormattedPrice(
              (
                getCountryInfoByLanguage(getCurrentLanguage()) || {
                  countryCode: "US",
                  currency: "USD"
                }
              ).currency,
              user.credit,
              (
                getCountryInfoByLanguage(getCurrentLanguage()) || {
                  countryCode: "US",
                  currency: "USD"
                }
              ).countryCode
            )}{" "}
          </p>
        </div>
      );
    default:
      return <h2 className={styles["k42-navbar-header"]}>{user.fullname}</h2>;
  }
};

export default NavbarAdditions;
