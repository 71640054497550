import convertPrice from "./convertPrice";

export default (currency, price, country) => {
  const convertedPrice = Math.round(convertPrice(price, currency) * 10) / 10;
  const formattedPrice = convertedPrice.toLocaleString(country, {
    style: "currency",
    currency: currency,
    currencyDisplay: "code"
  });

  return formattedPrice;
};
