import React from "react";
import { jobActions, guideActions, dialogActions, menuActions, authActions } from "../../actions";
import { bindActionCreators } from "redux";
import { getCurrentLanguage } from "../../actions/auth";
import { connect } from "react-redux";
import styles from "./Jobs.module.scss";
import JobList from "../../components/JobList";
import TopMenu from "../../components/TopMenu";
import {
  PRINT,
  DELETE,
  // DUPLICATE,
  UNMARK_FAVORITE,
  MARK_FAVORITE,
  SETTINGS
} from "../../constants/General";
import Dialog from "../Dialog";
import getFormattedPrice from "../../common/utils/getFormattedPrice";
import getCountryInfoByLanguage from "../../common/utils/getCountryInfoByLanguage";
class Jobs extends React.Component {
  componentDidMount() {
    this.props.guideActions.next();
  }

  action = action => {
    const selectedJobsCount = this.props.jobs.filter(job => job.selected).length;
    const user = this.props.user;
    const selectedJobsPricesSum = this.props.jobs
      .filter(job => job.selected)
      .map(x => x.price)
      .reduce((a, b) => a + b, 0);
    switch (action) {
      // case DUPLICATE:
      //   this.props.jobActions.duplicateJobsRequest();
      //   return setTimeout(() => this.props.jobActions.duplicateJobsSuccess(), 400); // Animation
      case PRINT:
        if (
          user.username === "credit" &&
          (user.credit <= 0.1 || selectedJobsPricesSum > user.credit)
        ) {
          this.props.dialogActions.openDialog("A69", "Not enough credit");
          break;
        } else {
          user.username === "quota" && this.props.authActions.changeQuota(selectedJobsCount);
          user.username === "credit" && this.props.authActions.changeCredit(selectedJobsPricesSum);
          this.props.jobActions.printJobsRequest();
          this.props.guideActions.next();
          this.print(selectedJobsCount);
          return setTimeout(() => {
            this.props.jobActions.printJobsSuccess(selectedJobsCount);
          }, 400); // Animation
        }
      case MARK_FAVORITE:
        this.props.jobActions.favoritJobsRequest();
        return setTimeout(() => this.props.jobActions.favoritJobsSuccess(), 400); // Animation
      case DELETE:
        this.props.jobActions.deleteJobsRequest();
        return setTimeout(() => this.props.jobActions.deleteJobsSuccess(selectedJobsCount), 400); // Animation
      case UNMARK_FAVORITE:
        this.props.jobActions.unfavoritJobsRequest();
        return setTimeout(() => this.props.jobActions.unfavoritJobsSuccess(), 400); // Animation
      case SETTINGS:
        if (this.props.jobs.filter(job => job.selected)[0].duplex) {
          this.props.dialogActions.openDialog("A301", "Print Options");
        } else {
          this.props.dialogActions.openDialog("A300", "Print Options");
        }
        break;
      default:
    }
  };

  navigateBack = () => {
    this.props.history.goBack();
  };

  toggleAllJobs = () => {
    this.props.jobActions.toggleJobAllJobs();
  };

  selectTab = tab => {
    this.props.jobActions.selectTab(tab);
  };

  toggleJob = row => {
    this.props.guideActions.next();
    this.props.jobActions.toggleJob(row);
  };

  getActiveTab = tabs => {
    return tabs.filter(tab => tab.active).reduce(t => t);
  };

  isActionMenuHidden = jobs => {
    return !jobs.reduce((acc, job) => acc || job.selected, false);
  };

  print = (count = 1) => {
    this.props.history.push(`/animation/print/${count}`);
  };

  allJobsSelected = () => {
    return (
      this.props.jobs.filter(job => job.selected && !job.printed).length ===
      this.props.jobs.filter(job => !job.printed).length
    );
  };

  oneJobSelected = () => {
    return this.props.jobs.filter(job => job.selected).length === 1;
  };
  render() {
    const selectedJobsPricesSum = this.props.jobs
      .filter(job => job.selected)
      .map(x => x.price)
      .reduce((a, b) => a + b, 0);

    const creditString = getFormattedPrice(
      (getCountryInfoByLanguage(getCurrentLanguage()) || { countryCode: "US", currency: "USD" })
        .currency,
      this.props.credit,
      this.props.user.country
    );
    const selectedJobsPricesSumString = getFormattedPrice(
      (getCountryInfoByLanguage(getCurrentLanguage()) || { countryCode: "US", currency: "USD" })
        .currency,
      selectedJobsPricesSum,
      this.props.user.country
    );
    return (
      <div className={styles["k42-jobs"]}>
        <TopMenu
          tabs={this.props.tabs}
          action={this.selectTab}
          toggleAll={this.toggleAllJobs}
          navigateBack={this.navigateBack}
          pricesSum={selectedJobsPricesSumString}
          userCredit={creditString}
          allJobsSelected={this.allJobsSelected()}
          oneJobSelected={this.oneJobSelected()}
          hidden={false}
        />
        <TopMenu
          tabs={this.props.actions}
          action={this.action}
          toggleAll={this.toggleAllJobs}
          navigateBack={this.navigateBack}
          isActionMenu={true}
          pricesSum={selectedJobsPricesSumString}
          userCredit={creditString}
          allJobsSelected={this.allJobsSelected()}
          oneJobSelected={this.oneJobSelected()}
          hidden={this.isActionMenuHidden(this.props.jobs)}
        />
        <JobList
          list={this.props.jobs}
          action={this.action}
          activeTab={this.getActiveTab(this.props.tabs)}
          toggleRow={this.toggleJob}
          country={this.props.user.country}
        />
        <Dialog scan={() => {}} print={this.print} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.jobs,
  credit: state.auth.credit,
  dialog: state.dialog,
  user: state.auth
});

const mapDispatchToProps = dispatch => ({
  jobActions: bindActionCreators(jobActions, dispatch),
  guideActions: bindActionCreators(guideActions, dispatch),
  dialogActions: bindActionCreators(dialogActions, dispatch),
  menuActions: bindActionCreators(menuActions, dispatch),
  authActions: bindActionCreators(authActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Jobs);
