import React from "react";
import { Button, Col } from "react-onsenui";
import Icon from "../Icon";

let styles = {};
import(/* webpackMode: "eager" */
`./${process.env.REACT_APP_VENDOR}/DialPad.module.scss`).then(importedStyles => {
  styles = importedStyles.default;
});

const DialButton = ({ value, callback }) => {
  const handleClick = event => {
    event.preventDefault();
    callback(value);
  };

  return (
    <Col>
      <Button
        className={styles["k42-dial-button"]}
        modifier="material--flat"
        ripple={false}
        style={{
          paddingTop: typeof value === "string" && 20
        }}
        onClick={handleClick}>
        {typeof value === "number" && value}
        {typeof value === "string" && <Icon name={value} size={"medium"} />}
      </Button>
    </Col>
  );
};

export default DialButton;
