import React from "react";
import Icon from "../Icon";
import { Link } from "react-router-dom";
import NavbarAdditions from "./NavbarAdditions";
import Avatar from "../Avatar/Avatar";
let styles = {};

import(/* webpackMode: "eager" */
`./${process.env.REACT_APP_VENDOR}/Navbar.module.scss`).then(importedStyles => {
  styles = importedStyles.default;
});

const Navbar = ({ user, logout, level, navigateBack }) => (
  <div
    className={styles["k42-navbar"]}
    style={
      process.env.REACT_APP_VENDOR === "myq" && user.background === "white"
        ? { backgroundColor: "white", color: "black" }
        : {}
    }>
    <div onClick={navigateBack} className={styles["k42-navbar-logo-container"]}>
      {level > 0 && (
        <Icon className={styles["k42-navbar-logo"]} name={"back_black"} user={user.id} />
      )}
      <Icon className={styles["k42-navbar-logo"]} name={"user"} user={user.id} />
    </div>
    <NavbarAdditions {...{ user }} />
    <div className="k42-spacer" />
    <Link to="/login" onClick={logout}>
      <Icon name={"logout"} className={styles["k42-navbar-icon"]} size="large" user={user.id} />
    </Link>
  </div>
);

export default Navbar;
