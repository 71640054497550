import {
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  SAVE_DIALOG_VALUE,
  CHANGE_QUOTA,
  LANGUAGE_SUCCESS,
  CHANGE_CREDIT
} from "../constants/ActionTypes";

const initialState = {
  logoutError: false,
  loginError: false,
  authError: false,
  authenticated: false,
  cause: null,
  message: null,
  username: null,
  fullname: null,
  credit: null,
  id: null,
  topmenutype: null,
  copiesLeft: null,
  limit: null,
  quota: null
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return Object.assign({}, state, {});

    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        message: "Login successful",
        cause: null,
        username: action.username,
        fullname: action.fullname,
        credit: action.credit,
        id: action.id,
        topmenutype: action.topmenutype,
        authenticated: true,
        copiesLeft: action.copiesLeft,
        limit: action.limit,
        quota: action.quota,
        background: action.background,
        customStyle: action.customStyle
      });

    case SAVE_DIALOG_VALUE: {
      if (action.name === "Language Selection") {
        return Object.assign({}, state, {});
      }
      return state;
    }

    case LOGIN_FAILURE:
      return Object.assign({}, initialState, {
        message: "Login failed",
        cause: "Unknown User"
      });

    case LOGOUT_REQUEST:
      return Object.assign({}, state, {});

    case LOGOUT_SUCCESS:
      return Object.assign({}, initialState);

    case LOGOUT_FAILURE:
      return Object.assign({}, state, {});

    case LANGUAGE_SUCCESS:
      return state;

    case CHANGE_QUOTA:
      return Object.assign({}, state, {
        copiesLeft: state.copiesLeft + action.jobCount
      });

    case CHANGE_CREDIT:
      return Object.assign({}, state, {
        credit: state.credit - action.sum
      });

    default:
      return state;
  }
}
