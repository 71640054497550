import React from "react";
import { withTranslation } from "react-i18next";
import { Button, Page, AlertDialog, Tabbar, Tab, Toolbar } from "react-onsenui";
import { DialogPage, DialogPrompt } from "../../components/Dialog";
import styles from "./Dialog.module.scss";

const Dialog = ({
  handleAction,
  setActiveTab,
  opened,
  label,
  activeTab,
  type,
  content,
  buttons,
  t,
  dialogActions
}) => {
  let inputValue = "";

  const renderTabs = tabs => {
    return tabs.map((tab, index) => ({
      tab: <Tab key={index} label={t(tab.name)} icon={tab.icon} />,
      content: (
        <DialogPage
          key={index}
          fields={tab.content}
          handleAction={handleAction}
          dialogActions={dialogActions}
        />
      )
    }));
  };

  const renderToolbar = label => {
    return (
      <Toolbar className={styles["k42-dialog-toolbar"]}>
        <div className="center">{t(label)}</div>
      </Toolbar>
    );
  };

  const renderButtons = buttons => {
    return buttons.map((button, index) => (
      <Button
        modifier="quiet"
        key={index}
        onClick={() => handleAction(button.action, inputValue, label)}
        className="alert-dialog-button alert-dialog-button--rowfooter alert-dialog-button--rowfooter--material alert-dialog-button--material alert-dialog-button--rowfooter--rowfooter">
        {t(button.name)}
      </Button>
    ));
  };

  const handlePromptChange = value => {
    inputValue = value;
  };
  return (
    <AlertDialog
      onCancel={() => handleAction(104)}
      isOpen={opened}
      modifier="rowfooter"
      isCancelable={true}>
      <div className={`alert-dialog-content ${styles["k42-dialog-" + type]}`}>
        <Page renderToolbar={() => renderToolbar(label)} className={styles["k42-dialog-menu-page"]}>
          {type === "tabs" && (
            <Tabbar
              swipeable={false}
              onPreChange={({ index }) => setActiveTab(index)}
              onPostChange={() => console.log("postChange")}
              onReactive={() => console.log("postChange")}
              position="auto"
              index={activeTab}
              renderTabs={() => renderTabs(content)}
            />
          )}
          {type === "list" && (
            <DialogPage
              fields={content}
              dialogActions={dialogActions}
              name={label}
              handleAction={handleAction}
            />
          )}
          {type === "prompt" && <DialogPrompt label={content} handleAction={handlePromptChange} />}
          {type === "alert" && <DialogPage />}
          {type === "password" && (
            <DialogPrompt label={content} password={true} handleAction={handlePromptChange} />
          )}
        </Page>
      </div>
      <div className="alert-dialog-footer">{renderButtons(buttons)}</div>
    </AlertDialog>
  );
};

export default withTranslation()(Dialog);
