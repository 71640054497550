import React from "react";
import { Icons } from "../../constants";
import(`./${process.env.REACT_APP_VENDOR}/Icon.scss`);

const getIconDirectory = user => {
  switch (process.env.REACT_APP_VENDOR) {
    case "myq":
      if (user === "55") {
        return "55";
      }
      if (user === "44") {
        return "44";
      }
      return "myq";
    case "ta":
      return "ta";
    case "utax":
      return "utax";
    case "knm":
      return "myq";
    default:
      return "myq";
  }
};

const Icon = ({ name, size, className, user }) => {
  const iconName = Icons[(name || "").toUpperCase()];
  return iconName ? (
    <img
      alt={`Icon ${name}`}
      className={`k42-icon-${size || "default"} ${className || ""} k42-icon-${name}`}
      src={`${process.env.PUBLIC_URL}/icon/${getIconDirectory(user)}/${iconName}.png`}
    />
  ) : (
    <div />
  );
};

export default Icon;
