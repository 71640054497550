import React from "react";
import { menuActions, authActions } from "../../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Navbar from "../../components/Navbar";
import styles from "./ApplicationList.module.scss";
import { withTranslation } from "react-i18next";

class ApplicationList extends React.Component {
  menuItems = [
    "image_part_001",
    "image_part_002",
    "image_part_003",
    "image_part_004",
    "image_part_005",
    "image_part_006",
    "image_part_007",
    "image_part_008",
    "image_part_009",
    "image_part_010",
    "image_part_011",
    "image_part_012"
  ];

  render() {
    const {
      auth: { fullname },
      authActions,
      t
    } = this.props;

    return (
      <div className={styles["k42-application-list"]}>
        <Navbar
          level={1}
          user={fullname}
          navigateBack={this.props.history.goBack}
          logout={authActions.logoutUser}
        />
        <h2>{t("Here is the place for your applications")}</h2>
        {/* TASK 128 - DELETE IMAGES and INCLUDE ONLY TEXT*/}
        {/* this.menuItems.map(item => (
          <div className={styles["k42-application-icon"]}>
            <img
              className={styles["k42-application-icon-img"]}
              alt={`Application List`}
              src={`${process.env.PUBLIC_URL}/img/${item}.png`}
            />
          </div>
        )) */}
      </div>
    );
  }
}

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  menuActions: bindActionCreators(menuActions, dispatch),
  authActions: bindActionCreators(authActions, dispatch)
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ApplicationList)
);
