export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const LANGUAGE_SUCCESS = "LANGUAGE_SUCCESS";
export const LANGUAGE_SUCCESS_APP = "LANGUAGE_SUCCESS_APP";
export const COUNTRY_SUCCESS = "COUNTRY_SUCCESS";

export const GET_MENU_REQUEST = "GET_MENU_REQUEST";
export const GET_MENU_SUCCESS = "GET_MENU_SUCCESS";
export const GET_MENU_FAILURE = "GET_MENU_FAILURE";
export const RESET_MENU = "RESET_MENU";
export const REMOVE_MENU_REQUEST = "REMOVE_MENU_REQUEST";
export const REMOVE_MENU_SUCCESS = "REMOVE_MENU_SUCCESS";
export const REMOVE_MENU_FAILURE = "REMOVE_MENU_FAILURE";
export const PRINT_ALL = "PRINT_ALL";
export const CHANGE_QUOTA = "CHANGE_QUOTA";
export const CHANGE_CREDIT = "CHANGE_CREDIT";

export const OPEN_DIALOG_SUCCESS = "OPEN_DIALOG_SUCCESS";
export const OPEN_DIALOG_FAILURE = "OPEN_DIALOG_FAILURE";
export const CLOSE_DIALOG_SUCCESS = "CLOSE_DIALOG_SUCCESS";
export const CLOSE_DIALOG_FAILURE = "CLOSE_DIALOG_FAILURE";
export const SET_DIALOG_ACTIVE_TAB = "SET_DIALOG_ACTIVE_TAB";
export const SAVE_DIALOG_VALUE = "SAVE_DIALOG_VALUE";

export const GET_JOBS_SUCCESS = "GET_JOBS_SUCCESS";
export const GET_JOBS_FAILURE = "GET_JOBS_FAILURE";
export const ADD_JOB_SUCCESS = "ADD_JOB_SUCCESS";
export const DUPLICATE_JOBS_SUCCESS = "DUPLICATE_JOBS_SUCCESS";
export const DUPLICATE_JOBS_REQUEST = "DUPLICATE_JOBS_REQUEST";
export const PRINT_JOBS_SUCCESS = "PRINT_JOB_SUCCESS";
export const PRINT_JOBS_REQUEST = "PRINT_JOBS_REQUEST";
export const DELETE_JOBS_SUCCESS = "DELETE_JOB_SUCCESS";
export const DELETE_JOBS_REQUEST = "DELETE_JOBS_REQEUST";
export const TOGGLE_JOB_SUCCESS = "TOGGLE_JOB_SUCCESS";
export const TOGGLE_ALL_JOBS_SUCCESS = "TOGGLE_ALL_JOBS_SUCCESS";
export const FAVORITE_JOBS_REQUEST = "FAVORITE_JOBS_REQUEST";
export const FAVORITE_JOBS_SUCCESS = "FAVORITE_JOBS_SUCCESS";
export const UNFAVORITE_JOBS_SUCCESS = "UNFAVORITE_JOBS_SUCCESS";
export const UNFAVORITE_JOBS_REQUEST = "UNFAVORITE_JOBS_REQUEST";

export const SELECT_TAB_SUCCESS = "SELECT_TAB_SUCCESS";

// Guide
export const GUIDE_NEXT_STEP = "GUIDE_NEXT_STEP";
export const GUIDE_BACK_STEP = "GUIDE_BACK_STEP";
export const SKIP_GUIDE = "SKIP_GUIDE";
export const INIT_GUIDE = "INIT_GUIDE";
